import { getCookieData } from "../GetData";

var isLogin = false;
if (getCookieData("LoginStatus") !== undefined) {
  isLogin = JSON.parse(getCookieData("LoginStatus"));
}
const initialAuthState = {
  AccessToken: "",
  data: [],
  disability: false,
  subcategory: [],
  category: [],
  subcategorydata: [],
  productdata: [],
  tab: [],
  listItem: [],
  listStage: 1,
  LoginStatus: isLogin,
  signupCMS: [],
  ListEditCMS: [],
  delegateCMS: [],
  AdCMS: [],
  OfferCms: [],
  createListingCategory: [],
  SearchData: "",
  addressModal: {
    show: false,
    Address: [],
  },
  advalues: {},
  selectadimage: undefined,
  selectadvalues: [],
  delegateCode: null,
  ItineraryValue: [],
  ItineraryImage: [],
  driverCMS: [],
  createList: [],
  manageList: [],
  isLoading: true,
  appointmentactiveTab: {
    maps: true,
    profile: true,
    service: true,
    review: true,
    status: 0,
  },
  Categories: [],
  Successmodal: {
    show: false,
    msg: "",
  },
  FailureModal: {
    show: false,
    msg: "",
  },
  aboutCMS: [],
};

const productFeature = (state, action) => {
  return {
    ...state,
    productdata: action.productdata,
  };
};

const TabValue = (state, action) => {
  return {
    ...state,
    tab: action.tab,
  };
};

const ListValue = (state, action) => {
  return {
    ...state,
    listItem: action.listItem,
  };
};
const ListId = (state, action) => {
  return {
    ...state,
    listId: action.listId,
  };
};
const ListStage = (state, action) => {
  return {
    ...state,
    listStage: action.listStage,
  };
};

const LoginStatus = (state, action) => {
  return {
    ...state,
    LoginStatus: action.LoginStatus,
  };
};

const signupSEO = (state, action) => {
  return {
    ...state,
    signupCMS: action.signupCMS,
  };
};

const listeditSEO = (state, action) => {
  return {
    ...state,
    ListEditCMS: action.ListEditCMS,
  };
};

const delegateSEO = (state, action) => {
  return {
    ...state,
    delegateCMS: action.delegateCMS,
  };
};

const AdSEO = (state, action) => {
  return {
    ...state,
    AdCMS: action.AdCMS,
  };
};

const CreateListingData = (state, action) => {
  return {
    ...state,
    createListingCategory: action.createListingCategory,
  };
};

const CreateListingSearch = (state, action) => {
  return {
    ...state,
    SearchData: action.SearchData,
  };
};

const AddressModalConfigure = (state, action) => {
  return {
    ...state,
    addressModal: action.addressModal,
  };
};

const CreateAdValues = (state, action) => {
  return {
    ...state,
    advalues: action.advalues,
  };
};

const SelectAdValues = (state, action) => {
  return {
    ...state,
    selectadvalues: action.selectadvalues,
  };
};

const SelectAdImage = (state, action) => {
  return {
    ...state,
    selectadimage: action.selectadimage,
  };
};

const delegatecode = (state, action) => {
  return {
    ...state,
    delegateCode: action.delegateCode,
  };
};

const ItineraryValueUpdate = (state, action) => {
  return {
    ...state,
    ItineraryValue: action.ItineraryValue,
  };
};

const ItineraryImageUpdate = (state, action) => {
  return {
    ...state,
    ItineraryImage: action.ItineraryImage,
  };
};

const driverCMSUpdate = (state, action) => {
  return {
    ...state,
    driverCMS: action.driverCMS,
  };
};

const OfferCMSUpdate = (state, action) => {
  return {
    ...state,
    OfferCms: action.OfferCms,
  };
};

const CreateListData = (state, action) => {
  return {
    ...state,
    createList: action.createList,
  };
};

const ManageListData = (state, action) => {
  return {
    ...state,
    manageList: action.manageList,
  };
};

const LoaderEnabled = (state, action) => {
  return {
    ...state,
    isLoading: action.isLoading,
  };
};

const verifyAppointment = (state, action) => {
  return {
    ...state,
    appointmentactiveTab: action.appointmentactiveTab,
  };
};

const CategoryStore = (state, action) => {
  return {
    ...state,
    Categories: action.Categories,
  };
};

const SuccessModalShow = (state, action) => {
  return {
    ...state,
    Successmodal: action.Successmodal,
  };
};

const FailureModalShow = (state, action) => {
  return {
    ...state,
    FailureModal: action.FailureModal,
  };
};

const aboutCMSData = (state, action) => {
  return {
    ...state,
    aboutCMS: action.aboutCMS,
  };
};

function rootReducer(state = initialAuthState, action) {
  switch (action.type) {
    case "PRODUCT_FEATURE":
      return productFeature(state, action);
    case "TAB_VALUE":
      return TabValue(state, action);
    case "LIST_DATA":
      return ListValue(state, action);
    case "LIST_ID":
      return ListId(state, action);
    case "LIST_STAGE":
      return ListStage(state, action);
    case "LOGIN_STATUS":
      return LoginStatus(state, action);
    case "HEADER_SEO":
      return signupSEO(state, action);
    case "LIST_EDIT":
      return listeditSEO(state, action);
    case "DELEGATE_SEO":
      return delegateSEO(state, action);
    case "AD_CMS":
      return AdSEO(state, action);
    case "OFFER_CMS":
      return OfferCMSUpdate(state, action);
    case "CREATE_LISTING_CATEGORY":
      return CreateListingData(state, action);
    case "CREATE_LISTING_SEARCH":
      return CreateListingSearch(state, action);
    case "ADDRESS_CONFIGURE":
      return AddressModalConfigure(state, action);
    case "CREATE_AD_VALUES":
      return CreateAdValues(state, action);
    case "SELECT_AD_VALUES":
      return SelectAdValues(state, action);
    case "SELECT_AD_IMAGE":
      return SelectAdImage(state, action);
    case "DELEGATE_CODE":
      return delegatecode(state, action);
    case "ITINERARY_VALUE_UPDATE":
      return ItineraryValueUpdate(state, action);
    case "ITINERARY_IMAGE_UPDATE":
      return ItineraryImageUpdate(state, action);
    case "DRIVER_CMS_DATA":
      return driverCMSUpdate(state, action);
    case "CREATE_LIST_DATA":
      return CreateListData(state, action);
    case "MANAGE_LIST_DATA":
      return ManageListData(state, action);
    case "LOADER_STATUS":
      return LoaderEnabled(state, action);
    case "APPOINTMENT_VERIFY_TAB_CONFIGURE":
      return verifyAppointment(state, action);
    case "CATEGORY_STORE":
      return CategoryStore(state, action);
    case "SUCCESS_MODAL_SHOW":
      return SuccessModalShow(state, action);
    case "FAILURE_MODAL_SHOW":
      return FailureModalShow(state, action);
    case "ABOUT_CMS_DATA":
      return aboutCMSData(state, action);
    default:
      return state || null;
  }
}

export { rootReducer };
