const React = require("react");
require("./src/pages/common.scss");
const { Provider } = require("react-redux");
const { rootReducer } = require("./src/store/Reducer/Reducer");
const { createStore } = require("redux");
const { getCookieData } = require("./src/store/GetData");
const { navigate } = require("gatsby");
const queryString = require("query-string");
const { Sessionstore } = require("./src/store/StoreData");
const { DeleteSessionstore } = require("./src/store/DeleteData");

const store = createStore(rootReducer);

exports.wrapRootElement = ({ element }) => {
  return <Provider store={store}>{element}</Provider>;
};

exports.onClientEntry = () => {
  const parsed = queryString.parse(window.location.search);
  const userToken = getCookieData("access_token");
  var pathData = [
    "/about",
    "/privacypolicy",
    "/terms",
    "/contact",
    "/resources",
    "/apply",
    "/openpositions",
    "/workforce",
    "/blog",
    "/signup",
  ];
  if (
    userToken === undefined &&
    !pathData.includes(window.location.pathname) &&
    !window.location.pathname.includes("blog") &&
    !window.location.pathname.includes("openpositions")
  ) {
    window.location.assign("/signup");
    navigate("/signup");
  } else if (
    userToken !== undefined &&
    window.location.pathname === "/signup"
  ) {
    window.location.assign("/home");
    navigate("/home");
  }
  if (window.location.pathname === "/" && userToken === undefined) {
    if (parsed?.code !== undefined) {
      Sessionstore("referalCode", parsed.code);
    } else {
      DeleteSessionstore("referalCode");
    }
    window.location.assign("/signup");
    navigate("/signup");
  } else if (userToken !== undefined) {
    if (parsed?.code !== undefined) {
      Sessionstore("referalCode", parsed.code);
      window.location.assign(`/delegate/submit`);
      navigate(`/delegate/submit`);
    } else {
      if (window.location.pathname !== "/delegate/submit") {
        DeleteSessionstore("referalCode");
      }
      if (window.location.pathname === "/" && userToken !== undefined) {
        window.location.assign(`/home`);
        navigate(`/home`);
      }
    }
  }
};

exports.shouldUpdateScroll = ({ routerProps: { location } }) => {
  const { pathname } = location;
  const scrollToTopRoutes = [
    `/service/outcomes`,
    `/service/feature`,
    `/service/about`,
    `/service/addpicture`,
    `/service/servicearea`,
    `/product/feature`,
    `/product/additionalfeature`,
    `/product/variation`,
    `/product/addpicture`,
    `/travel/timings`,
    `/travel/feature`,
    `/travel/addpicture`,
    `/travel/itinerary`,
    `/paratransit/timing`,
    `/paratransit/addpicture`,
    `/paratransit/servicearea`,
    `/DashBoard/daily`,
    `/DashBoard/weekly`,
    `/DashBoard/monthly`,
    `/about/advisor`,
    `/about/enabler`,
    `/about/doers`,
  ];
  if (!scrollToTopRoutes.includes(pathname)) {
    window.scrollTo(0, 0);
  }
  return false;
};

exports.onPreRouteUpdate = ({ location, prevLocation }) => {
  const parsed = queryString.parse(window.location.search);
  const userToken = getCookieData("access_token");
  var pathData = [
    "/about",
    "/privacypolicy",
    "/terms",
    "/contact",
    "/resources",
    "/apply",
    "/openpositions",
    "/workforce",
    "/blog",
    "/signup",
  ];
  if (
    userToken === undefined &&
    !pathData.includes(window.location.pathname) &&
    !window.location.pathname.includes("blog") &&
    !window.location.pathname.includes("openpositions")
  ) {
    window.location.assign("/signup");
    navigate("/signup");
  } else if (
    userToken !== undefined &&
    window.location.pathname === "/signup"
  ) {
    window.location.assign("/home");
    navigate("/home");
  }
  if (window.location.pathname === "/" && userToken === undefined) {
    if (parsed?.code !== undefined) {
      Sessionstore("referalCode", parsed.code);
    } else {
      DeleteSessionstore("referalCode");
    }
    window.location.assign("/signup");
    navigate("/signup");
  } else if (userToken !== undefined) {
    if (parsed?.code !== undefined) {
      Sessionstore("referalCode", parsed.code);
      window.location.assign(`/delegate/submit`);
      navigate(`/delegate/submit`);
    } else {
      if (window.location.pathname !== "/delegate/submit") {
        DeleteSessionstore("referalCode");
      }
      if (window.location.pathname === "/" && userToken !== undefined) {
        window.location.assign(`/home`);
        navigate(`/home`);
      }
    }
  }
};
